import { useStyles } from '@team-griffin/stylr';
import { gu2px } from '@ux/theme-utils';
import {
  content,
  smallprint,
} from '@ux/type-size';
import {
  percentage,
  modifier,
} from '@ux/style-utils';
import { Layout } from 'components/common/ProductPrice/constants';
import { getTitleColor, getContrastColor } from 'presentation/utils/colorway';
import { InnerProps } from './ProductPrice';
import { CSSProperties } from 'react';

export interface Styles {
  root: CSSProperties,
  intro: CSSProperties,
  badge: CSSProperties,
  price: CSSProperties,
  setupFee: CSSProperties,
  fee: CSSProperties,
  renewal: CSSProperties,
  legalCaveat: CSSProperties,
  priceWrapper: CSSProperties,
  pricing: CSSProperties,
}
export default ({
  theme,
  isMinSm,
  isMinLg,
  colorway,
  priceLayout,
  hasProposition,
  hasBadge,
}: InnerProps) => {
  const {
    spacing,
    typography,
  } = theme;

  return useStyles({
    default: {
      root: {
        marginTop: gu2px(3, spacing),
      },
      intro: {
        color: getTitleColor(theme, colorway),
        marginTop: gu2px(2, spacing),
        ...content(typography),
      },
      badge: {
        marginLeft: gu2px(-1, spacing),
      },
      price: {
        color: getContrastColor(theme, colorway),
        marginRight: gu2px(1, spacing),
      },
      setupFee: {
        color: getTitleColor(theme, colorway),
        marginTop: gu2px(1, spacing),
      },
      fee: {
        color: getContrastColor(theme, colorway),
      },
      renewal: {
        color: getTitleColor(theme, colorway),
      },
      legalCaveat: {
        ...smallprint(typography),
        marginTop: gu2px(1, spacing),
        opacity: 0.9,
      },
    },
    [modifier('priceLayout', Layout.COMPACT)]: {
      priceWrapper: {
        display: 'flex',
        width: percentage(100),
        alignItems: 'center',
        justifyContent: 'space-between',
      },
      pricing: {
        flex: 1,
        flexBasis: 'auto',
        textAlign: 'right',
      },
      setupFee: {
        textAlign: 'right',
        marginTop: 0,
      },
    },
    badgeAndNoProposition: {
      pricing: {
        marginTop: gu2px(2, spacing),
      },
    },
  }, [
    { isMinSm },
    { isMinLg },
    { priceLayout },
    { badgeAndNoProposition: hasBadge && !hasProposition && priceLayout !== Layout.COMPACT },
  ]);
};