import { withProps } from '@team-griffin/rehook';
import { createStructuredSelector } from 'reselect';
import * as r from 'ramda';
import { LegalText } from '@ux/product';
import pureEnhance from '@ux/pure-enhance';

const enhance = pureEnhance('LegalText', [
  withProps(createStructuredSelector({
    interval: r.path([ 'pricingStrategy', 'pricingTier', 'paymentInterval' ]),
    term: r.path([ 'pricingStrategy', 'pricingTier', 'term' ]),
  })),
]);

export default enhance(LegalText);
