import React from 'react';
import {
  mapProps,
  withProps,
} from '@team-griffin/rehook';
import { FormattedMessage } from 'react-intl';
import { Display } from '@ux/typeface';
import Saving from 'components/common/Saving';
import {
  Heading04,
  Heading06,
  SubHeading02,
} from '@ux/typography';
import stylesheet, { Styles } from './ProductPrice.stylesheet';
import { BreakpointProps, withBreakpointsHook } from '@ux/responsive';
import * as r from 'ramda';
import {
  isNotNilOrEmpty,
} from '@team-griffin/capra';
import LegalText from './LegalText';
import Shortcode from 'components/Shortcode';
import Badge, { Kind as BadgeKind } from '@ux/badge';
import Prices from '@ux/prices';
import { RenewalPeriod, Product } from '@ux/product';
import {
  getDisplayPeriod,
} from './utils';
import { RibbonProps } from 'domain/constants/ribbons';
import { Layout } from 'components/common/ProductPrice/constants';
import pureEnhance from '@ux/pure-enhance';

const hasSavingValue = r.propSatisfies(
  r.both(
    r.is(Object),
    r.has('value')
  ),
  'saving',
);

const hasBadge = r.anyPass([
  hasSavingValue,
  r.propEq('hasDiscount', true),
  r.propSatisfies(isNotNilOrEmpty, 'badge'),
]);

// returns true if we are in a viewMode not in given array
//   0 = retail price with setup cost underneath (default)
//   1 = no setup cost, price incs. upfront cost
//   2 = no setup cost, just show retail
const excludeViewModes = (viewModes: number[], props: DefaultProps) => r.pipe(
  r.path([ 'pricingStrategy', 'viewMode' ]),
  //@ts-ignore
  r.includes(
    r.__,
    viewModes,
  ),
  r.not,
  // @ts-ignore
)(props);

const shouldShowSetupFee = r.allPass([
  // check product has a setup fee
  r.propEq('hasUpFrontCost', true),
  // check whether showFee is not false
  r.propEq('showFee', true),
  //check we are in a valid view mode (0)
  (props) => excludeViewModes([ 1, 2 ], props),
]);

// eslint-disable-next-line no-underscore-dangle
export const __test__ = {
  shouldShowSetupFee,
};

interface OuterProps extends RibbonProps {
  showFee?: boolean,
  showLegalCaveat?: boolean,
  price: number,
  priceLayout?: Layout,
  viewMode: number,
  currency: string,
  discountPerc: string,
  product: Product,
  pricingStrategy: {
    proposition?: string,
    paymentIntervalDisplay: number,
    viewMode: number,
    pricingTier: {
      paymentInterval: number,
      term: number,
    }
  },
  hasDiscount?: boolean,
  badge?: string,
  upFront?: number,
  legalText?: string,
  saving?: {
    type: string,
    value: number,
    currency: string,
  },
  displayInterval: number,
  interval: number,
  term: number,
}

interface DefaultProps extends OuterProps {
  showFee: true,
  showLegalCaveat: true,
}

export interface InnerProps extends DefaultProps, BreakpointProps {
  styles: Styles,
  proposition: string,
  hasProposition: boolean,
  hasSaving: boolean,
  showSetupFee: boolean,
  renewalPeriod: number,
  hasBadge: boolean,
}
const PureProductPrice = ({
  theme,
  colorway,
  styles,
  currency,
  price,
  upFront,
  renewalPeriod,
  hasSaving,
  saving,
  badge,
  proposition,
  hasProposition,
  showSetupFee,
  showLegalCaveat,
  legalText,
  product,
  pricingStrategy,
  hasDiscount,
  discountPerc,
}: InnerProps) => (
  <div style={styles.root}>
    <div style={styles.priceWrapper}>
      <Choose>
        <When condition={hasSaving}>
          <Saving
            theme={theme}
            colorway={colorway}
            intlNamespace="usf.price"
            {...saving}
          />
        </When>
        <When condition={Boolean(badge)}>
          <span style={styles.badge}>
            <Badge
              theme={theme}
              kind={BadgeKind.PRIMARY}
            >
              {badge}
            </Badge>
          </span>
        </When>
        <When condition={hasDiscount}>
          <span style={styles.badge}>
            <Badge
              theme={theme}
              kind={BadgeKind.HIGHLIGHT}
            >
              <FormattedMessage
                id="usf.price.saving.percentage"
                values={{
                  saving: `${parseInt(discountPerc, 10)}%`,
                }}
              />
            </Badge>
          </span>
        </When>
      </Choose>
      <div style={styles.pricing}>
        <If condition={hasProposition}>
          <Heading06
            style={styles.intro}
            theme={theme}
            component="div"
          >
            <Shortcode
              theme={theme}
              against={colorway}
              code={proposition}
            />
          </Heading06>
        </If>
        <Display theme={theme}>
          <FormattedMessage
            id="usf.price.display"
            values={{
              price: (
                <Heading04
                  style={styles.price}
                  theme={theme}
                  component="span"
                >
                  <Prices
                    amount={price}
                    currency={currency}
                  />
                </Heading04>
              ),
              renewal: (
                <Heading06
                  style={styles.renewal}
                  theme={theme}
                  component="span"
                >
                  <RenewalPeriod period={renewalPeriod}/>
                </Heading06>
              ),
            }}
          />
        </Display>
      </div>
    </div>
    <If condition={showSetupFee}>
      <SubHeading02
        style={styles.setupFee}
        component="div"
        theme={theme}
      >
        <Display theme={theme}>
          <FormattedMessage
            id="usf.price.additionalFee"
            values={{
              fee: (
                <span style={styles.fee}>
                  <Prices
                    amount={upFront}
                    currency={currency}
                  />
                </span>
              ),
            }}
          />
        </Display>
      </SubHeading02>
    </If>
    <If condition={showLegalCaveat}>
      <div style={styles.legalCaveat}>
        <LegalText
          bespoke={legalText}
          product={product}
          pricingStrategy={pricingStrategy}
        />
      </div>
    </If>
  </div>
);
const enhance = pureEnhance<InnerProps, OuterProps>(
  'ProductPrice', {
    hooks: [
      // @ts-ignore
      mapProps(r.mergeRight({
        showFee: true,
        showLegalCaveat: true,
      })),
      withProps((ownerProps: DefaultProps) => ({
        proposition: r.path([ 'pricingStrategy', 'proposition' ], ownerProps),
        hasProposition: r.pathSatisfies(
          isNotNilOrEmpty,
          [ 'pricingStrategy', 'proposition' ],
          ownerProps
        ),
        hasSaving: hasSavingValue(ownerProps),
        showSetupFee: shouldShowSetupFee(ownerProps),
        renewalPeriod: getDisplayPeriod(ownerProps),
        hasBadge: hasBadge(ownerProps),
      })),
      withBreakpointsHook,
      withProps(stylesheet),
    ],
  }

);

export default enhance(PureProductPrice);
