import React from 'react';
import Billboard, { BillboardProps } from 'components/ribbons/billboard';
import ProductPrice, {
  LegalText,
} from 'components/common/ProductPrice';
import errorBoundary from 'presentation/hocs/errorBoundary';
import pureEnhance from '@ux/pure-enhance';
import { Product } from '@ux/product';

interface OuterProps extends BillboardProps {
  product: Product,
  currency: string,
  pricingStrategy?: {
    proposition?: string,
    paymentIntervalDisplay: number,
    viewMode: number,
    pricingTier: {
      paymentInterval: number,
      term: number,
    }
  },
}

interface InnerProps extends OuterProps {}

const PureBillboardProduct = ({
  theme,
  colorway,
  product,
  currency,
  pricingStrategy,
  footerText,
  ...billboardProps
}: InnerProps) => (
  <Billboard
    theme={theme}
    colorway={colorway}
    {...billboardProps}
    additionalText={(
      <ProductPrice
        theme={theme}
        colorway={colorway}
        currency={currency}
        pricingStrategy={pricingStrategy}
        product={product}
        showLegalCaveat={false}
      />
    )}
    footerText={(
      <LegalText
        bespoke={footerText}
        pricingStrategy={pricingStrategy}
        product={product}
      />
    )}
  />
);

const enhance = pureEnhance<InnerProps, OuterProps>(
  'BillboardProduct',
  {
    hocs: [
      errorBoundary,
    ],
  }
);

export default enhance(PureBillboardProduct);
