import React from 'react';
import {
  compose,
  setDisplayName,
  defaultProps,
  withProps,
} from 'recompose';
// import { fastCompare } from '@ux/perf';
import {
  FormattedNumber, FormattedMessage,
} from 'react-intl';
import Message from '@ux/message';
import Badge, { Kind as BadgeKind } from '@ux/badge';
import { Display } from '@ux/typeface';
import { withBreakpoints } from '@ux/responsive';
import { gu2px } from '@ux/theme-utils';
import * as r from 'ramda';
import { Heading06 } from '@ux/typography';
import { useStyles } from '@team-griffin/stylr';

const stylesheet = ({
  theme,
}) => {
  const {
    spacing,
    palette,
  } = theme;

  return useStyles({
    default: {
      root: {
        marginLeft: r.negate(gu2px(1, spacing)),
      },
      percentage: {
        fontWeight: 'normal',
        color: palette.highlight,
        verticalAlign: 'middle',
      },
      fixed: {
        fontWeight: 'normal',
        color: palette.highlight,
        verticalAlign: 'middle',
        textDecoration: 'line-through',
      },
    },
  }, []);
};

const isPercentage = r.propEq('type', 'percentage');
const isFixed = r.propEq('type', 'fixed');

const Saving = r.cond([
  [ isPercentage, ({
    value,
    intlNamespace,
  }) => (
    <FormattedMessage
      id={`${intlNamespace}.saving.percentage`}
      values={{
        saving: (<span>{`${value}%`}</span>),
      }}
    />
  ) ],
  [ isFixed, ({
    value,
    currency,
    intlNamespace,
  }) => (
    <FormattedMessage
      id={`${intlNamespace}.saving.fixed`}
      values={{
        saving: (
          <FormattedNumber
            value={value}
            // eslint-disable-next-line react/style-prop-object
            style="currency"
            currency={currency}
          />
        ),
      }}
    />
  ) ],
  [ r.T, r.always(null) ],
]);

export const PureSaving = ({
  styles,
  theme,
  type,
  value,
  currency,
  intlNamespace,
}) => (
  <Display
    theme={theme}
    style={styles.root}
  >
    <Badge
      theme={theme}
      kind={BadgeKind.HIGHLIGHT}
    >
      <Message id={`${intlNamespace}.saving.badge`}/>
    </Badge>
    <Heading06
      style={styles[type]}
      theme={theme}
      component="span"
    >
      <Saving
        type={type}
        value={value}
        currency={currency}
        intlNamespace={intlNamespace}
      />
    </Heading06>
  </Display>
);

export const enhance = compose(
  setDisplayName('Saving'),
  // fastCompare,
  defaultProps({
    intlNamespace: 'usf',
  }),
  withBreakpoints,
  withProps(stylesheet),
);

export default enhance(PureSaving);
