// @fabric
import pureEnhance from '@ux/pure-enhance';
import { withProps } from '@team-griffin/rehook';
import { createStructuredSelector } from 'reselect';
import { connect } from '@ux/fabric';
import ProductPrice from './ProductPrice';
import { selectors, productPrice } from '@ux/product';
import * as r from 'ramda';

const { shouldIncludeTax } = selectors;

const mapStateToProps = createStructuredSelector({
  includeTax: shouldIncludeTax,
});

const enhance = pureEnhance('ConnectedProductPrice', [
  withProps(connect(mapStateToProps)),
  withProps(createStructuredSelector({
    displayInterval: r.path([ 'pricingStrategy', 'paymentIntervalDisplay' ]),
    viewMode: r.path([ 'pricingStrategy', 'viewMode' ]),
    interval: r.path([ 'pricingStrategy', 'pricingTier', 'paymentInterval' ]),
    term: r.path([ 'pricingStrategy', 'pricingTier', 'term' ]),
  })),
  productPrice(),
]);

export default enhance(ProductPrice);
