import * as r from 'ramda';
import * as rA from 'ramda-adjunct';
import { selectors } from '@ux/product';

const { getInterval } = selectors;

export const getDisplayPeriod = rA.dispatch([
  r.path([ 'pricingStrategy', 'paymentIntervalDisplay' ]),
  getInterval,
]);

export default null;
